import {
  IAddDeleteWidgetData,
  ICreateDashboardWidgetRequest,
  IDashboard,
  IDashboardImpl,
  IPatchDashboard,
  IPatchDashboardSuccess,
  IUpdateWidgetRequest,
  IWeatherInformation,
  IWidgetImpl,
  IWidgetLocations,
} from '@app/shared/models/dashboard.model';
import { IMachineServiceEventsResponse, IMachines, IAlarmServiceEvents } from '@app/shared/models/machines.model';
import { Action } from '@ngrx/store';
import { StateError } from '..';
import {
  IAvailableShop,
  IDealerDataRegistrationResponse,
  IRegisterOrganisationDealer,
  IRegistrationCurrentOrganisationAddress,
  ShopKey,
} from 'cde-fe-organization-registration-dialog';
import { IGetOrganisationDealerWidgetContext } from '@app/shared/models/shops.model';
import { Machine } from '@app/shared/models/analytics.model';

export const UPDATE_OLD_SUCCESS = '[Dashboard] Update Old Success';
export const UPDATE_OLD_FAILURE = '[Dashboard] Update Old Failure';
export const UPDATE_OLD_COMPLETE = '[Dashboard] Update Old Complete';
export const UPDATE_OLD = '[Dashboard] Update Old';

export const WIDGETLOCATIONS_SUCCESS = '[Dashboard] WidgetLocations Success';
export const WIDGETLOCATIONS_FAILURE = '[Dashboard] WidgetLocations Failure';
export const WIDGETLOCATIONS_COMPLETE = '[Dashboard] WidgetLocations Complete';
export const WIDGETLOCATIONS = '[Dashboard] WidgetLocations';

export const CREATE_SUCCESS = '[Dashboard] Create Success';
export const CREATE_FAILURE = '[Dashboard] Create Failure';
export const CREATE_COMPLETE = '[Dashboard] Create Complete';
export const CREATE = '[Dashboard] Create';

export const DELETE_SUCCESS = '[Dashboard] Delete Success';
export const DELETE_FAILURE = '[Dashboard] Delete Failure';
export const DELETE_COMPLETE = '[Dashboard] Delete Complete';
export const DELETE = '[Dashboard] Delete';

export const UPDATE_SUCCESS = '[Dashboard] Update Success';
export const UPDATE_FAILURE = '[Dashboard] Update Failure';
export const UPDATE_COMPLETE = '[Dashboard] Update Complete';
export const UPDATE = '[Dashboard] Update';

export const GETBYID_SUCCESS = '[Dashboard] GetByID Success';
export const GETBYID_FAILURE = '[Dashboard] GetByID Failure';
export const GETBYID_COMPLETE = '[Dashboard] GetByID Complete';
export const GETBYID = '[Dashboard] GetByID';

export const GETALL_SUCCESS = '[Dashboard] GetAll Success';
export const GETALL_FAILURE = '[Dashboard] GetAll Failure';
export const GETALL_COMPLETE = '[Dashboard] GetAll Complete';
export const GETALL = '[Dashboard] GetAll';

export const GET_DASHBOARDS_AFTER_ORG_CREATION = '[Dashboard] Get Dashboards After Org Creation';

export const CREATE_WIDGET_SUCCESS = '[Dashboard] Create Widget Success';
export const CREATE_WIDGET_FAILURE = '[Dashboard] Create Widget Failure';
export const CREATE_WIDGET_COMPLETE = '[Dashboard] Create Widget Complete';
export const CREATE_WIDGET = '[Dashboard] Create Widget';

export const DELETE_WIDGET_SUCCESS = '[Dashboard] Delete Widget Success';
export const DELETE_WIDGET_FAILURE = '[Dashboard] Delete Widget Failure';
export const DELETE_WIDGET_COMPLETE = '[Dashboard] Delete Widget Complete';
export const DELETE_WIDGET = '[Dashboard] Delete Widget';

export const UPDATE_WIDGET_SUCCESS = '[Dashboard] Update Widget Success';
export const UPDATE_WIDGET_FAILURE = '[Dashboard] Update Widget Failure';
export const UPDATE_WIDGET_COMPLETE = '[Dashboard] Update Widget Complete';
export const UPDATE_WIDGET = '[Dashboard] Update Widget';

export const GETBYID_WIDGET_SUCCESS = '[Dashboard] GetByID Widget Success';
export const GETBYID_WIDGET_FAILURE = '[Dashboard] GetByID Widget Failure';
export const GETBYID_WIDGET_COMPLETE = '[Dashboard] GetByID Widget Complete';
export const GETBYID_WIDGET = '[Dashboard] GetByID Widget';

export const GETALL_WIDGET_SUCCESS = '[Dashboard] GetAll Widget Success';
export const GETALL_WIDGET_FAILURE = '[Dashboard] GetAll Widget Failure';
export const GETALL_WIDGET_COMPLETE = '[Dashboard] GetAll Widget Complete';
export const GETALL_WIDGET = '[Dashboard] GetAll Widget';

export const GETWEATHER_INFO_SUCCESS = '[Dashboard] GetWeatherInfo Success';
export const GETWEATHER_INFO_FAILURE = '[Dashboard] GetWeatherInfo Failure';
export const GETWEATHER_INFO_COMPLETE = '[Dashboard] GetWeatherInfo Complete';
export const GETWEATHER_INFO = '[Dashboard] GetWeatherInfo Widget';

export const MACHINES_LIST = '[Dashboard] Machines List';
export const MACHINES_LIST_SUCCESS = '[Dashboard] Machines List Success';
export const MACHINES_LIST_FAILURE = '[Dashboard] Machines List Failure';
export const MACHINES_LIST_COMPLETE = '[Dashboard] Machines List Complete';

export const SERVICEEVENTS_LIST = '[Dashboard] ServiceEvents List';
export const SERVICEEVENTS_LIST_SUCCESS = '[Dashboard] ServiceEvents List Success';
export const SERVICEEVENTS_LIST_FAILURE = '[Dashboard] ServiceEvents List Failure';
export const SERVICEEVENTS_LIST_COMPLETE = '[Dashboard] ServiceEvents List Complete';

export const ALARMEVENTS_LIST = '[Dashboard] AlarmEvents List';
export const ALARMEVENTS_LIST_SUCCESS = '[Dashboard] AlarmEvents List Success';
export const ALARMEVENTS_LIST_FAILURE = '[Dashboard] AlarmEvents List Failure';
export const ALARMEVENTS_LIST_COMPLETE = '[Dashboard] AlarmEvents List Complete';

export const DIGITAL_DELIVERY_PENDING_MACHINES_LIST = '[Dashboard] Digital Delivery Pending Machines List';
export const DIGITAL_DELIVERY_PENDING_MACHINES_LIST_SUCCESS = '[Dashboard] Digital Delivery Pending Machines List Success';
export const DIGITAL_DELIVERY_PENDING_MACHINES_LIST_FAILURE = '[Dashboard] Digital Delivery Pending Machines List Failure';
export const DIGITAL_DELIVERY_PENDING_MACHINES_LIST_COMPLETE = '[Dashboard] Digital Delivery Pending Machines List Complete';

export const UPDATE_DASHBOARD_AFTER_ORG_REGISTRATION = '[Dashboard] Update Dashboard After Org Registration';
export const UPDATE_DEALER_WIDGET_AFTER_CHANGE = '[Dashboard] Update Dealer Widget After Change';
export const UPDATE_DEALER_WIDGET_AFTER_NOT_FOUND = '[Dashboard] Update Dealer Widget After Not Found';

export const RESET_DASHBOARD = '[Dashboard] Reset Dashboard';

export class Create implements Action {
  readonly type = CREATE;

  constructor(public payload: IDashboard) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;

  constructor(public payload: IDashboardImpl | undefined) {}
}

export class CreateFailure implements Action {
  readonly type = CREATE_FAILURE;

  constructor(public payload: StateError) {}
}

export class CreateComplete implements Action {
  readonly type = CREATE_COMPLETE;
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;

  constructor(public payload: string | undefined) {}
}

export class DeleteFailure implements Action {
  readonly type = DELETE_FAILURE;

  constructor(public payload: StateError) {}
}

export class DeleteComplete implements Action {
  readonly type = DELETE_COMPLETE;
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: { id: string; value: IPatchDashboard }) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: boolean, public updateDashboardResult: IPatchDashboardSuccess) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: StateError) {}
}

export class UpdateComplete implements Action {
  readonly type = UPDATE_COMPLETE;
}

export class GetByID implements Action {
  readonly type = GETBYID;

  constructor(public payload: string) {}
}

export class GetByIDSuccess implements Action {
  readonly type = GETBYID_SUCCESS;

  constructor(public payload: IDashboardImpl | undefined) {}
}

export class GetByIDFailure implements Action {
  readonly type = GETBYID_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetByIDComplete implements Action {
  readonly type = GETBYID_COMPLETE;
}

export class GetAll implements Action {
  readonly type = GETALL;

  constructor(public dealerRegistrationResponse?: IDealerDataRegistrationResponse) {}
}

export class GetDashboardsAfterOrgCreation implements Action {
  readonly type = GET_DASHBOARDS_AFTER_ORG_CREATION;

  constructor(
    public newOrgUuid: string,
    public shops: ShopKey[],
    public dealer: IRegisterOrganisationDealer,
    public organisationAddress: IRegistrationCurrentOrganisationAddress
  ) {}
}

export class GetAllSuccess implements Action {
  readonly type = GETALL_SUCCESS;

  constructor(public payload: Array<IDashboardImpl>, public dealerRegistrationResponse?: IDealerDataRegistrationResponse) {}
}

export class GetAllFailure implements Action {
  readonly type = GETALL_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetAllComplete implements Action {
  readonly type = GETALL_COMPLETE;
}

export class CreateWidget implements Action {
  readonly type = CREATE_WIDGET;

  constructor(public payload: ICreateDashboardWidgetRequest) {}
}

export class CreateWidgetSuccess implements Action {
  readonly type = CREATE_WIDGET_SUCCESS;

  constructor(public payload: IWidgetImpl | undefined, public dashboardId: string) {}
}

export class CreateWidgetFailure implements Action {
  readonly type = CREATE_WIDGET_FAILURE;

  constructor(public payload: StateError) {}
}

export class CreateWidgetComplete implements Action {
  readonly type = CREATE_WIDGET_COMPLETE;
}

export class DeleteWidget implements Action {
  readonly type = DELETE_WIDGET;

  constructor(public payload: IAddDeleteWidgetData) {}
}

export class DeleteWidgetSuccess implements Action {
  readonly type = DELETE_WIDGET_SUCCESS;

  constructor(public payload: IAddDeleteWidgetData) {}
}

export class DeleteWidgetFailure implements Action {
  readonly type = DELETE_WIDGET_FAILURE;

  constructor(public payload: StateError) {}
}

export class DeleteWidgetComplete implements Action {
  readonly type = DELETE_WIDGET_COMPLETE;
}

export class UpdateWidget implements Action {
  readonly type = UPDATE_WIDGET;

  constructor(public payload: IUpdateWidgetRequest) {}
}

export class UpdateWidgetSuccess implements Action {
  readonly type = UPDATE_WIDGET_SUCCESS;

  constructor(public payload: IWidgetImpl, public dashboardId: string) {}
}

export class UpdateWidgetFailure implements Action {
  readonly type = UPDATE_WIDGET_FAILURE;

  constructor(public payload: StateError) {}
}

export class UpdateWidgetComplete implements Action {
  readonly type = UPDATE_WIDGET_COMPLETE;
}

export class GetWidgetByID implements Action {
  readonly type = GETBYID_WIDGET;

  constructor(public payload: { dashboardId: string; widgetId: string }) {}
}

export class GetWidgetByIDSuccess implements Action {
  readonly type = GETBYID_WIDGET_SUCCESS;

  constructor(public payload: IWidgetImpl | undefined) {}
}

export class GetWidgetByIDFailure implements Action {
  readonly type = GETBYID_WIDGET_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetWidgetByIDComplete implements Action {
  readonly type = GETBYID_WIDGET_COMPLETE;
}

export class GetAllWidget implements Action {
  readonly type = GETALL_WIDGET;

  constructor(public payload: string) {}
}

export class GetAllWidgetSuccess implements Action {
  readonly type = GETALL_WIDGET_SUCCESS;

  constructor(public payload: Array<IWidgetImpl> | undefined) {}
}

export class GetAllWidgetFailure implements Action {
  readonly type = GETALL_WIDGET_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetAllWidgetComplete implements Action {
  readonly type = GETALL_WIDGET_COMPLETE;
}

export class UpdateOld implements Action {
  readonly type = UPDATE_OLD;

  constructor(public payload: IWidgetLocations) {}
}

export class UpdateOldSuccess implements Action {
  readonly type = UPDATE_OLD_SUCCESS;

  constructor(public payload: string) {}
}

export class UpdateOldFailure implements Action {
  readonly type = UPDATE_OLD_FAILURE;

  constructor(public payload: StateError) {}
}

export class UpdateOldComplete implements Action {
  readonly type = UPDATE_OLD_COMPLETE;
}

export class WidgetLocations implements Action {
  readonly type = WIDGETLOCATIONS;

  constructor(public payload: boolean) {}
}

export class WidgetLocationsSuccess implements Action {
  readonly type = WIDGETLOCATIONS_SUCCESS;

  constructor(public payload: string | null) {}
}

export class WidgetLocationsFailure implements Action {
  readonly type = WIDGETLOCATIONS_FAILURE;

  constructor(public payload: StateError) {}
}

export class WidgetLocationsComplete implements Action {
  readonly type = WIDGETLOCATIONS_COMPLETE;
}

export class GetWeatherInfo implements Action {
  readonly type = GETWEATHER_INFO;

  constructor(public payload: { language: string; latitude: number; longitude: number; id: string }) {}
}

export class GetWeatherInfoSuccess implements Action {
  readonly type = GETWEATHER_INFO_SUCCESS;

  constructor(public payload: IWeatherInformation | undefined) {}
}

export class GetWeatherInfoFailure implements Action {
  readonly type = GETWEATHER_INFO_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetWeatherInfoComplete implements Action {
  readonly type = GETWEATHER_INFO_COMPLETE;

  constructor(public payload: string) {}
}

export class MachinesList implements Action {
  readonly type = MACHINES_LIST;
  // constructor(public payload: { ids: string[]; timeZone: string }) {}
}

export class MachinesListSuccess implements Action {
  readonly type = MACHINES_LIST_SUCCESS;
  constructor(public payload: IMachines[]) {}
}

export class MachinesListFailure implements Action {
  readonly type = MACHINES_LIST_FAILURE;
  constructor(public payload: StateError) {}
}

export class MachinesListComplete implements Action {
  readonly type = MACHINES_LIST_COMPLETE;
}

export class ServiceEventsList implements Action {
  readonly type = SERVICEEVENTS_LIST;
  constructor(public payload: { timeZone: string; language: string }) {}
}

export class ServiceEventsListSuccess implements Action {
  readonly type = SERVICEEVENTS_LIST_SUCCESS;
  constructor(public payload: IMachineServiceEventsResponse | undefined) {}
}

export class ServiceEventsListFailure implements Action {
  readonly type = SERVICEEVENTS_LIST_FAILURE;
  constructor(public payload: StateError) {}
}

export class ServiceEventsListComplete implements Action {
  readonly type = SERVICEEVENTS_LIST_COMPLETE;
}
export class AlarmEventsList implements Action {
  readonly type = ALARMEVENTS_LIST;
  constructor(public payload: { measuredAtLocalDate: string; timeZone: string; language: string }) {}
}

export class AlarmEventsListSuccess implements Action {
  readonly type = ALARMEVENTS_LIST_SUCCESS;
  constructor(public payload: IAlarmServiceEvents[] | undefined) {}
}

export class AlarmEventsListFailure implements Action {
  readonly type = ALARMEVENTS_LIST_FAILURE;
  constructor(public payload: StateError) {}
}

export class AlarmEventsListComplete implements Action {
  readonly type = ALARMEVENTS_LIST_COMPLETE;
}

export class DigitalDeliveryPendingMachinesList implements Action {
  readonly type = DIGITAL_DELIVERY_PENDING_MACHINES_LIST;
}

export class DigitalDeliveryPendingMachinesListSuccess implements Action {
  readonly type = DIGITAL_DELIVERY_PENDING_MACHINES_LIST_SUCCESS;

  constructor(public payload: Machine[]) {}
}

export class DigitalDeliveryPendingMachinesListFailure implements Action {
  readonly type = DIGITAL_DELIVERY_PENDING_MACHINES_LIST_FAILURE;

  constructor(public payload: StateError) {}
}

export class DigitalDeliveryPendingMachinesListComplete implements Action {
  readonly type = DIGITAL_DELIVERY_PENDING_MACHINES_LIST_COMPLETE;
}

export class UpdateDashboardAfterOrgRegistration implements Action {
  readonly type = UPDATE_DASHBOARD_AFTER_ORG_REGISTRATION;

  constructor(
    public newOrgUuid: string,
    public shops: ShopKey[],
    public dealer: IRegisterOrganisationDealer,
    public organisationAddress: IRegistrationCurrentOrganisationAddress
  ) {}
}

export class UpdateDealerWidgetAfterChange implements Action {
  readonly type = UPDATE_DEALER_WIDGET_AFTER_CHANGE;

  constructor(public payload: IDealerDataRegistrationResponse) {}
}

export class UpdateDealerWidgetAfterNotFound implements Action {
  readonly type = UPDATE_DEALER_WIDGET_AFTER_NOT_FOUND;

  constructor(public context: IGetOrganisationDealerWidgetContext) {}
}

export class ResetDashboard implements Action {
  readonly type = RESET_DASHBOARD;
}

export type Actions =
  | Create
  | CreateSuccess
  | CreateFailure
  | CreateComplete
  | Delete
  | DeleteSuccess
  | DeleteFailure
  | DeleteComplete
  | Update
  | UpdateSuccess
  | UpdateFailure
  | UpdateComplete
  | GetByID
  | GetByIDSuccess
  | GetByIDFailure
  | GetByIDComplete
  | GetAll
  | GetAllSuccess
  | GetAllFailure
  | GetAllComplete
  | CreateWidget
  | CreateWidgetSuccess
  | CreateWidgetFailure
  | CreateWidgetComplete
  | DeleteWidget
  | DeleteWidgetSuccess
  | DeleteWidgetFailure
  | DeleteWidgetComplete
  | UpdateWidget
  | UpdateWidgetSuccess
  | UpdateWidgetFailure
  | UpdateWidgetComplete
  | GetWidgetByID
  | GetWidgetByIDSuccess
  | GetWidgetByIDFailure
  | GetWidgetByIDComplete
  | GetAllWidget
  | GetAllWidgetSuccess
  | GetAllWidgetFailure
  | GetAllWidgetComplete
  | UpdateOld
  | UpdateOldSuccess
  | UpdateOldFailure
  | UpdateOldComplete
  | WidgetLocations
  | WidgetLocationsSuccess
  | WidgetLocationsFailure
  | WidgetLocationsComplete
  | GetWeatherInfo
  | GetWeatherInfoSuccess
  | GetWeatherInfoFailure
  | GetWeatherInfoComplete
  | MachinesList
  | MachinesListSuccess
  | MachinesListFailure
  | MachinesListComplete
  | ServiceEventsList
  | ServiceEventsListSuccess
  | ServiceEventsListFailure
  | ServiceEventsListComplete
  | AlarmEventsList
  | AlarmEventsListSuccess
  | AlarmEventsListFailure
  | AlarmEventsListComplete
  | DigitalDeliveryPendingMachinesList
  | DigitalDeliveryPendingMachinesListSuccess
  | DigitalDeliveryPendingMachinesListFailure
  | DigitalDeliveryPendingMachinesListComplete
  | UpdateDashboardAfterOrgRegistration
  | GetDashboardsAfterOrgCreation
  | UpdateDealerWidgetAfterChange
  | UpdateDealerWidgetAfterNotFound
  | ResetDashboard;
